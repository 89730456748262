const baseUrl = "https://smartlightingdemo.cloudemsportal.com/";

async function updateCueList(body) {
    let url = baseUrl + 'api/dmx/v1/app/cue-list';
    let data = await (await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })).json();

    return data;
}

async function getCueList() {
    let url = baseUrl + 'api/dmx/v1/app/cue-list';
    let data = await (await fetch(url)).json();
    return data;
}

async function updateSetting(body) {
    let url = baseUrl + 'api/dmx/v1/app/settings';
    let data = await (await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        redirect: 'follow'
    })).json();

    return data;
}


async function getAppStatus() {
    let url = baseUrl + 'api/dmx/v1/app/status';
    let data = await (await fetch(url)).json();
    return data;
}

async function updateAppStatus(body) {
    let url = baseUrl + 'api/dmx/v1/app/status';
    let data = await (await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        redirect: 'follow'
    })).json();

    return data;
}



export default {
    updateCueList: updateCueList,
    getCueList: getCueList,
    updateSetting:updateSetting,
    getAppStatus:getAppStatus,
    updateAppStatus:updateAppStatus
}