<template>
<div class="">
    <div id="project-name">
        <span>DMX Lighting 2.0 Demo</span>
    </div>
    <!-- <b-table @click="onRowSelected" class="config-table" bordered hover :items="items" :fields="fields"></b-table> -->

    <table class="config-table table">
        <thead>
            <tr>
                <th style="text-align:center" scope="col">#</th>
                <th scope="col">Button Name</th>
                <th scope="col">Cue Number</th>
            </tr>
        </thead>
        <tbody>
            <tr @click="onSelectItem(item)" v-for="(item,index) in items" :key="index">
                <td style="text-align:center">{{item.id}}</td>
                <td>{{item.name}}</td>
                <td>{{item.value}}</td>
            </tr>
        </tbody>
    </table>
    <b-modal id="updateCueModal" ref="my-modal" centered v-model="showModal" title="Update Cue ">
        <div class="d-block">
            <div class="mb-3">
                <label class="form-label">Cue Name</label>
                <input v-model="tempItem.name" type="text" class="form-control" id="cuename">
            </div>
            <div class="mb-3">
                <label class="form-label">Value</label>
                <input @change="onValueEditChanged()" v-model="tempItem.value" type="number" class="form-control" id="cuevalue">
            </div>
        </div>
        <button type="button" @click="dontEdit()" class=" confirmBtn">Done</button>

    </b-modal>
    <button class="confirmBtn" @click="saveCue">Confirm</button>
        <button class="confirmBtn cancelBtn" @click="onCancel">Cancel</button>

</div>
</template>

<script>
import {
    defineComponent
} from "vue";
import appService from "../services/app";

export default defineComponent({
    name: "Login",

    mounted() {
        this.getCueList();
        this.showModal = false;
    },
    data() {
        return {
            items: [],
            defaultItems: [],
            tempItem: {},
            fields: [{
                    key: "id",
                    label: "#"
                },
                {
                    key: "name",
                    label: "Button Name"
                },
                {
                    key: "value",
                    label: "Cue Number"
                }
            ],
            showModal: false
        };
    },
    methods: {
        dontEdit() {
            if (this.tempItem.value < -1) {
                alert('The assigned cue number must be integer and great than 0');
            } else {
                this.showModal = false;
                let index = this.items.findIndex(ele => ele.id == this.tempItem.id);
                this.items[index] = JSON.parse(JSON.stringify(this.tempItem));
            }
        },
        onValueEditChanged() {
            console.log(this.tempItem)
        },
        onSelectItem(item) {
            this.showModal = true;
            this.tempItem = JSON.parse(JSON.stringify(item));
        },
        async getCueList() {
            let result = await appService.getCueList();
            this.items = result;
            this.defaultItems = JSON.parse(JSON.stringify(this.items));
        },
        async saveCue() {
            let body = {
                "setting": this.items
            }
            let result = await appService.updateCueList(body);
            if (result)
                // console.log(result);
             window.location.href = '/';
        },
        onCancel () {
            this.items = JSON.parse(JSON.stringify(this.defaultItems));

        }
    }
});
</script>

<style lang="scss" module>

</style>
